// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-alfabetize-js": () => import("./../src/pages/alfabetize.js" /* webpackChunkName: "component---src-pages-alfabetize-js" */),
  "component---src-pages-atividades-js": () => import("./../src/pages/atividades.js" /* webpackChunkName: "component---src-pages-atividades-js" */),
  "component---src-pages-combinado-js": () => import("./../src/pages/combinado.js" /* webpackChunkName: "component---src-pages-combinado-js" */),
  "component---src-pages-depoimentos-js": () => import("./../src/pages/depoimentos.js" /* webpackChunkName: "component---src-pages-depoimentos-js" */),
  "component---src-pages-ebook-js": () => import("./../src/pages/ebook.js" /* webpackChunkName: "component---src-pages-ebook-js" */),
  "component---src-pages-eventos-js": () => import("./../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mamaesquefazem-js": () => import("./../src/pages/mamaesquefazem.js" /* webpackChunkName: "component---src-pages-mamaesquefazem-js" */),
  "component---src-pages-maratona-js": () => import("./../src/pages/maratona.js" /* webpackChunkName: "component---src-pages-maratona-js" */),
  "component---src-pages-matematica-js": () => import("./../src/pages/matematica.js" /* webpackChunkName: "component---src-pages-matematica-js" */),
  "component---src-pages-parede-js": () => import("./../src/pages/parede.js" /* webpackChunkName: "component---src-pages-parede-js" */),
  "component---src-pages-redes-sociais-js": () => import("./../src/pages/redes-sociais.js" /* webpackChunkName: "component---src-pages-redes-sociais-js" */),
  "component---src-pages-relogio-js": () => import("./../src/pages/relogio.js" /* webpackChunkName: "component---src-pages-relogio-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-subscribed-atividades-js": () => import("./../src/pages/subscribed-atividades.js" /* webpackChunkName: "component---src-pages-subscribed-atividades-js" */),
  "component---src-pages-subscribed-combinado-js": () => import("./../src/pages/subscribed-combinado.js" /* webpackChunkName: "component---src-pages-subscribed-combinado-js" */),
  "component---src-pages-subscribed-ebook-js": () => import("./../src/pages/subscribed-ebook.js" /* webpackChunkName: "component---src-pages-subscribed-ebook-js" */),
  "component---src-pages-subscribed-newsletter-js": () => import("./../src/pages/subscribed-newsletter.js" /* webpackChunkName: "component---src-pages-subscribed-newsletter-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-successn-js": () => import("./../src/pages/successn.js" /* webpackChunkName: "component---src-pages-successn-js" */),
  "component---src-pages-unsubscribed-js": () => import("./../src/pages/unsubscribed.js" /* webpackChunkName: "component---src-pages-unsubscribed-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

